.App {
  height: 100%;
}

* {
  font-family: font-lato-regular !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

input {
  margin-bottom: 30px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
	font-family: 'font-lato-black';
	src: url('./assets/fonts/Lato-Black.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'font-lato-regular';
	src: url('./assets/fonts/Lato-Regular.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'font-lato-bold';
	src: url('./assets/fonts/Lato-Bold.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'font-lato-light';
	src: url('./assets/fonts/Lato-Light.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'font-lustria';
	src: url('./assets/fonts/Lustria-Regular.ttf') format('truetype');
	font-display: swap;
}
